<template>
  <div class="bodyconter">
    <div class="neiqu" v-for="(i,k) in datalist" :key="k">
      <div class="titlename">{{i.newsTitle}}</div>
      <div class="tiem">发布时间：{{i.createTime}}</div>
      <img class="img1" :src="i.img" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      datalist: []
    }
  },
  created() {
    this.uplist(this.$route.params.id)
  },
  mounted() { },
  watch: {},
  methods: {
    uplist(e) {
      this.api.newsDetail({
        id: e
      }).then((res) => {
        this.datalist = [res.data]
      })
    }
  },
  computed: {},
}
</script>
<style lang="scss" scoped>
.bodyconter {
  width: 100%;
  background: #fafafa;
  flex: 1;
  padding: 51px 0;
  .neiqu {
    width: 1200px;
    background: #ffffff;
    margin: auto;
    text-align: center;
    .img1 {
      margin: auto;
      margin-top: 41px;
      width: 800px;
      height: 486px;
    }
    .titlename {
      padding: 65px 0 19px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #981c1c;
    }
    .tiem {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      padding-bottom: 21px;
      border-bottom: 1px solid #eeeeee;
    }
  }
}
</style>
